// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// stylelint-disable property-no-unknown

@media screen and (min-width: $desktop-width) {
    .widget {

        // Hero Banner

        &-banner-image-wrapper {
            &.small,
            &.medium,
            &.large {
                height: auto;
            }
        }

        &-banner-label {
            min-width: $widget-banner-label__min-width--large;
            padding: $widget-banner-label__padding--large;
            font-size: $widget-banner-label__font-size--large;
            line-height: $widget-banner-label__line-height--large;

            &::after {
                margin-bottom: $widget-banner-label-after__margin-bottom--large;
            }
        }

        &-banner-content-wrapper-overlay {
            padding: $widget-hero-banner-content-wrapper__padding--large;
        }

        &-banner-content-wrapper {
            &.banner-content-half-width {
                .widget-banner-text-headline,
                .widget-banner-text-description {
                    width: $widget-hero-banner-content-half__width--large;
                }

                .widget-banner-text-description.banner-content-quarter-width {
                    width: $widget-hero-banner-full__width / 4;
                }
            }
        }

        &-brand-image-wrapper {
            position: absolute;
            z-index: 2;
            top: $widget-hero-banner-brand-image-wrapper-position__top;
            right: $widget-hero-banner-brand-image-wrapper-position__right;
            width: $widget-hero-banner-brand-image-wrapper__width;
            height: $widget-hero-banner-brand-image-wrapper__height;
        }

        &-banner-text-headline {
            margin-top: $widget-hero-banner-text-headline__margin-top--large;
        }

        &-banner-text-description {
            > * {
                margin: $widget-hero-banner-text-description__margin;
            }
        }

        &-banner-button {
            @include get-font('medium');
            width: $widget-hero-banner-button__width--large;
            padding: $widget-hero-banner-button__padding--large;
        }

        // Hero Banner in Four Column Grid

        &-category-grid {
            .widget-banner-text-description {
                > * {
                    margin: $widget-hero-banner-four-column-text-description__margin--large;
                }
            }

            .widget-banner-text-headline {
                margin-top: $widget-hero-banner-four-column-text-headline__margin-top--large;
            }

            .widget-banner-button-wrapper {
                margin-bottom: $base-padding * 3.3;
            }

            .widget-banner-button {
                width: $widget-hero-banner-four-column-button__width--large;
                padding: $widget-hero-banner-four-column-button__padding--large;
            }

            .widget-banner-content-wrapper-overlay {
                padding: $widget-hero-banner-four-column-content-wrapper__padding--large;
            }

            .widget-banner-content-wrapper {
                &.banner-content-half-width {
                    .widget-banner-text-headline,
                    .widget-banner-text-description {
                        width: $widget-hero-banner-full__width;
                    }
                }
            }

            .widget-brand-image-wrapper {
                width: $widget-hero-banner-four-column-brand-image-wrapper__width;
                height: $widget-hero-banner-four-column-brand-image-wrapper__height;
            }
        }

        // Hero Banner in Category Matrix Layout

        &-category-matrix {
            .widget-banner-content-wrapper-overlay {
                padding: $widget-hero-banner-category-matrix-content-wrapper__padding--large;
            }

            .widget-category-matrix-main-container {
                .widget-banner-button {
                    width: $widget-hero-banner-cat-matrix-button-big__width--large;
                    padding: $widget-hero-banner-cat-matrix-button__padding--large;
                }
            }

            .widget-category-matrix-side-container,
            .widget-category-matrix-bottom-container {
                .widget-banner-button {
                    width: $widget-hero-banner-cat-matrix-button-small__width--large;
                    padding: $widget-hero-banner-cat-matrix-button__padding--large;
                }
            }
        }

        // Hero Banner in Single Column

        &-single-column {
            .widget-hero-banner-container.large {
                .widget-banner-content-wrapper-overlay {
                    padding: $widget-hero-large-banner-content-wrapper__padding--large;
                }
            }

            .widget-hero-banner-container.small {
                .widget-banner-content-wrapper-overlay {
                    padding: $widget-hero-banner-single-column-content-wrapper__padding--large;
                }

                .widget-banner-button {
                    width: $widget-hero-banner-small-image-button__width--large;
                    padding: $widget-hero-banner-small-image-button__padding--large;
                }
            }
        }

        // Category Tile Widget

        &-category-main-wrapper {
            height: auto;
        }

        &-category-heading-wrapper {
            padding: $widget-category-heading-wrapper__padding--large;
        }

        &-category-heading-text {
            @include get-font('large');
        }

        &-subcategories {
            margin: $widget-subcategories__margin--large;
        }

        &-subcategory {
            @include get-font('compact');

            > .widget-category-tile-link {
                @include get-font('compact');
                padding-left: $widget-subcategory-link__padding-left--large;
            }
        }

        // HTML Block Widget

        &-html-block {
            &.text-indentation-enabled {
                width: $widget-assets-full__width / 2;
                margin: 0 auto;
            }

            &.text-indentation-enabled.right {
                margin: 0 0 0 auto;
            }

            &.left {
                margin: 0 auto 0 0;
            }

            &::before {
                font-size: $widget-html-block-icon__font-size--large;
            }
        }

        // Article Tile Widget

        &-article-date {
            @include get-font('xsmall');
        }

        &-article-title {
            @include get-font('medium');
            width: $widget-article-title__width--large;
            margin: $widget-article-title__margin;
        }

        &-article-description {
            > p {
                @include get-font('compact');
                line-height: $p__line-height;
            }
        }

        // Goal Banner Widget

        &-goal-banner-background-wrapper {
            height: $widget-goal-banner-background-wrapper__height--large;
        }

        &-goal-banner-title {
            @include get-font('enormous');
            width: $widget-goal-banner-title__width--large;
            margin: $widget-goal-banner-title__margin--large;
        }

        &-goal-banner-link {
            @include get-font('medium');
            display: inline;
            padding-right: $widget-goal-banner-link__pading-right--large;
            font-weight: $widget-goal-banner-link__font-weight--large;

            &:last-child {
                padding-right: 0;
            }
        }

        // Instagram Feed
        // stylelint-disable selector-max-compound-selectors
        // stylelint-disable max-nesting-depth

        &-three-columns {
            .widget-article-tile-image-wrapper {
                img {
                    aspect-ratio: 204/91;
                }
            }
        }

        &-two-columns {
            .widget-hero-banner-container {
                .widget-banner-image-wrapper {
                    img {
                        aspect-ratio: 211/94;
                    }
                }
            }
        }

        &-single-column {
            .widget-hero-banner-container {
                .widget-banner-image-wrapper {
                    img {
                        aspect-ratio: 288/95;
                    }
                }
            }
        }

        &-double-column {
            .widget-hero-banner-container {
                .widget-banner-image-wrapper {
                    img {
                        aspect-ratio: 3/2;
                    }
                }
            }
        }

        &-category-matrix {
            &-main-container {
                .widget-hero-banner-container {
                    .widget-banner-image-wrapper {
                        img {
                            aspect-ratio: 844/691;
                        }
                    }
                }
            }

            &-side-container,
            &-bottom-container {
                .widget-hero-banner-container {
                    .widget-banner-image-wrapper {
                        img {
                            aspect-ratio: 171/67;
                        }
                    }
                }
            }
        }
    }

    .copy-to-clipboard-message {
        top: -5.5rem;
    }
}
