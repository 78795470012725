// =============================================================================
//
//  Page Designer Dynamic Options CSS
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .pd-color-white {
        &.pd-mobile-color-white {
            color: $white !important;
        }
        
        &.pd-mobile-color-black {
            color: $white !important;
        }
        
        &.pd-mobile-color-red {
            color: $white !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $white !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $white !important;
        }
        
        &.pd-mobile-color-blue {
            color: $white !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $white !important;
        }
        
        &.pd-mobile-color-grey {
            color: $white !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $white !important;
        }
    }
    
    .pd-color-black {
        &.pd-mobile-color-white {
            color: $black !important;
        }
        
        &.pd-mobile-color-black {
            color: $black !important;
        }
        
        &.pd-mobile-color-red {
            color: $black !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $black !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $black !important;
        }
        
        &.pd-mobile-color-blue {
            color: $black !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $black !important;
        }
        
        &.pd-mobile-color-grey {
            color: $black !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $black !important;
        }
    }
    
    .pd-color-red {
        &.pd-mobile-color-white {
            color: $red !important;
        }
        
        &.pd-mobile-color-black {
            color: $red !important;
        }
        
        &.pd-mobile-color-red {
            color: $red !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $red !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $red !important;
        }
        
        &.pd-mobile-color-blue {
            color: $red !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $red !important;
        }
        
        &.pd-mobile-color-grey {
            color: $red !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $red !important;
        }
    }
    
    .pd-color-dark-red {
        &.pd-mobile-color-white {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-black {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-red {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-blue {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-grey {
            color: $dark-red !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $dark-red !important;
        }
    }

    .pd-color-wine-red {
        &.pd-mobile-color-white {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-black {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-red {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-blue {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-grey {
            color: $wine-red !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $wine-red !important;
        }
    }

    .pd-color-grey {
        &.pd-mobile-color-white {
            color: $grey !important;
        }
        
        &.pd-mobile-color-black {
            color: $grey !important;
        }
        
        &.pd-mobile-color-red {
            color: $grey !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $grey !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $grey !important;
        }
        
        &.pd-mobile-color-blue {
            color: $grey !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $grey !important;
        }
        
        &.pd-mobile-color-grey {
            color: $grey !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $grey !important;
        }
    }

    .pd-color-light-grey {
        &.pd-mobile-color-white {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-black {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-red {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-blue {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-grey {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $light-grey !important;
        }
    }

    .pd-color-blue {
        &.pd-mobile-color-white {
            color: $blue !important;
        }
        
        &.pd-mobile-color-black {
            color: $blue !important;
        }
        
        &.pd-mobile-color-red {
            color: $blue !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $blue !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $blue !important;
        }
        
        &.pd-mobile-color-blue {
            color: $blue !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $blue !important;
        }
        
        &.pd-mobile-color-grey {
            color: $blue !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $blue !important;
        }
    }

    .pd-color-light-blue {
        &.pd-mobile-color-white {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-black {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-red {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-dark-red {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-wine-red {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-blue {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-grey {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $light-blue !important;
        }
    }

    // Page Designer Dynimic Font Sizes for Desktop

    // stylelint-disable selector-class-pattern

    .pd-font-size-22px:not(.widget-html-block) {
        @include get-font('medium-big');

        > * {
            @include get-font('medium-big');
        }
    }

    .pd-font-size-24px:not(.widget-html-block) {
        @include get-font('almost-big');

        > * {
            @include get-font('almost-big');
        }
    }

    .pd-font-size-25px:not(.widget-html-block) {
        @include get-font('big');

        > * {
            @include get-font('big');
        }
    }

    .pd-font-size-26px:not(.widget-html-block) {
        @include get-font('large');

        > * {
            @include get-font('large');
        }
    }

    .pd-font-size-30px:not(.widget-html-block) {
        @include get-font('xlarge');

        > * {
            @include get-font('xlarge');
        }
    }

    .pd-font-size-32px:not(.widget-html-block) {
        @include get-font('huge');

        > * {
            @include get-font('huge');
        }
    }

    .pd-font-size-36px:not(.widget-html-block) {
        @include get-font('enormous');
        line-height: 1.1;

        > * {
            @include get-font('enormous');
            line-height: 1.1;
        }
    }

    .pd-font-size-46px:not(.widget-html-block) {
        @include get-font('gigantic');
        line-height: 1.1;

        > * {
            @include get-font('gigantic');
            line-height: 1.1;
        }
    }

    .widget {
        &-html-block {
            &.pd-font-size-22px {
                > p {
                    @include get-font('medium-big');
                }
            }
        
            &.pd-font-size-24px {
                > p {
                    @include get-font('almost-big');
                }
            }
        
            &.pd-font-size-25px {
                > p {
                    @include get-font('big');
                }
            }
        
            &.pd-font-size-26px {
                > p {
                    @include get-font('large');
                }
            }
        
            &.pd-font-size-30px {
                > p {
                    @include get-font('xlarge');
                }
            }
        
            &.pd-font-size-32px {
                > p {
                    @include get-font('huge');
                }
            }
        
            &.pd-font-size-36px {
                > p {
                    @include get-font('enormous');
                    line-height: 1.1;
                }
            }
        
            &.pd-font-size-46px {
                > p {
                    @include get-font('gigantic');
                    line-height: 1.1;
                }
            }
        }

        // Goald banner Desktop Dynamic Background Colors

        &-goal-banner-background-wrapper {
            &.pd-diagonal-background-white {
                .pd-diagonal-background-white {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $true-green $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $red $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $ocre $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-gold $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-black {
                .pd-diagonal-background-white {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $true-green $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $red $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
    
                .pd-diagonal-background-ocre {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $ocre $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-gold $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-true-green {
                .pd-diagonal-background-white {
                    background-color: $true-green;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $true-green $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $true-green;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $true-green $widget-layout-goal-banner-gradient-background-percentage__first--large, $true-green $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $true-green;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $true-green $widget-layout-goal-banner-gradient-background-percentage__first--large, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $true-green;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $true-green $widget-layout-goal-banner-gradient-background-percentage__first--large, $red $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $true-green;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $true-green $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $true-green;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $true-green $widget-layout-goal-banner-gradient-background-percentage__first--large, $ocre $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $true-green;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $true-green $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-gold $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-very-light-pink {
                .pd-diagonal-background-white {
                    background-color: $very-light-pink;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $very-light-pink;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__first--large, $true-green $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $very-light-pink;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__first--large, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $very-light-pink;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__first--large, $red $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $very-light-pink;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $very-light-pink;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__first--large, $ocre $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $very-light-pink;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-gold $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-red {
                .pd-diagonal-background-white {
                    background-color: $red;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $red;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red $widget-layout-goal-banner-gradient-background-percentage__first--large, $true-green $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $red;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red $widget-layout-goal-banner-gradient-background-percentage__first--large, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $red;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red $widget-layout-goal-banner-gradient-background-percentage__first--large, $red $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $red;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $red;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red $widget-layout-goal-banner-gradient-background-percentage__first--large, $ocre $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $red;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-gold $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-ocre {
                .pd-diagonal-background-white {
                    background-color: $ocre;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ocre $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $ocre;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ocre $widget-layout-goal-banner-gradient-background-percentage__first--large, $true-green $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $ocre;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ocre $widget-layout-goal-banner-gradient-background-percentage__first--large, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $ocre;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ocre $widget-layout-goal-banner-gradient-background-percentage__first--large, $red $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $ocre;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ocre $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $ocre;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ocre $widget-layout-goal-banner-gradient-background-percentage__first--large, $ocre $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $ocre;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ocre $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-gold $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-light-gold {
                .pd-diagonal-background-white {
                    background-color: $light-gold;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-gold $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $light-gold;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-gold $widget-layout-goal-banner-gradient-background-percentage__first--large, $true-green $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $light-gold;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-gold $widget-layout-goal-banner-gradient-background-percentage__first--large, $very-light-pink $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $light-gold;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-gold $widget-layout-goal-banner-gradient-background-percentage__first--large, $red $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $light-gold;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-gold $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $light-gold;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-gold $widget-layout-goal-banner-gradient-background-percentage__first--large, $ocre $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $light-gold;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-gold $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-gold $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
        }    
    }

}
